@import "../../../scss/landingsShared.scss";

$anchoDeCaja: $maxWith - (4 * $margin);

.dark {
  background-color: transparent;
  color: white;
}

.light {
  background-color: transparent;
  color: white;
}

.noBg {
  background-color: transparent !important;
  color: white !important;
}

.explainer {
  padding: 96px 71px 96px 71px;
  // margin-top: 96px !important;
  @include media-breakpoint-down(lg) {
    padding: 64px $marginMobile 64px $marginMobile !important;
    margin: -1px 0 0 0 !important;
  }
}

.main {
  max-width: $anchoDeCaja !important;
  margin-top: 48px;
  button {
    margin-left: 36px !important;
  }
}

.title {
  h2 {
    color: white;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "DM Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 100% */
    text-align: left;
    .spanText {
      color: $Naranja;
    }
  }
}

.copy {
  margin-top: 40px !important;
  color: $Amarillo;
  text-align: left;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
}

.standarList {
  text-align: left;
  padding-left: 0px;
  width: 100%;
  margin-bottom: 40px;
  li {
    background-image: url(../../../assets/svg/bulletLandingListHigligth.svg);
    margin-left: 0px;
    margin-bottom: 16px;
    list-style-type: none;
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 24px 24px !important;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.higligthList {
  margin-top: 40px;
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-bottom: 40px;
  li {
    background-image: url(../../../assets/svg/bulletLandingListHigligth.svg);
  }
}

.higligthListTitle {
  color: $Negro;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-align: left;
  margin-bottom: 0px;
  span {
    color: $Naranja;
  }
}
