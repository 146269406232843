@import "../../../scss/landingsShared.scss";

.main {
  height: 98px;
  padding-left: 72px;
  padding-right: 72px;
  display: flex;
  justify-content: space-between;
  align-content: center;

  @include media-breakpoint-down(md) {
    background-image: none;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 24px 0px 24px !important;
    height: 115px;
  }
}

.logoContainer {
  display: flex;
  justify-content: flex-start;

  @include media-breakpoint-down(md) {
    align-items: center;
    padding: 0px !important;
    img {
      height: 70px;
    }
  }
}

.ctaButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  text-align: right;
  padding: 0px;
  button {
    height: 50px;
    padding: 10px 20px 10px 20px;
    span {
      color: black;
      font-weight: 600;
      font-size: 16px;
      margin: 0 10px 0 0px;
    }
  }
  @include media-breakpoint-down(md) {
    // padding: 0px !important;
  }
}
