.landingForm {
  width: 548px;
  height: 617px;
  padding: 0px;
  background: #ffffff;
  border-radius: 24px;
}

placeholder {
  color: red !important;
}

@media (max-width: 1023px) {
  .landingForm {
    margin-top: 140px;
    min-height: 678px;
    textarea {
      height: 150px;
    }
  }
}
