footer {
  margin-bottom: 77px;
  margin-top: 230px;
  img {
    width: 180px;
    margin-bottom: 14px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}

@media (max-width: 1023px) {
  footer {
    // display: none;
    margin-top: 96px;
  }
}
