@import "../../scss/colors.scss";
@import "../../scss/layout.scss";
@import "../../scss/landingsShared.scss";

@function breakpoint($size) {
  @return map-get($grid-breakpoints, $size);
}

.servicios {
  min-height: 1412px;
  // margin-top: 96px !important;
  background-color: $bgLigth;
  color: black;
  padding-bottom: 96px;

  button {
    color: $grisOcuro;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
  }
}

.serviciosRow {
  margin-left: -24px;
  margin-right: -24px;
}

.main {
  margin-top: 96px;
  min-height: 1220px;

  h2 {
    color: $grisOcuro;
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "DM Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 96px;
  }

  h3 {
    text-align: center;
    font-family: "DM Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
  }
}

.priceClaim {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px !important;
}

.highlight {
  border-radius: 100px;
  border: 1px solid rgba(17, 17, 17, 0.16);
  background: var(--Blanco, #fff);
  position: absolute;
  padding: 8px 16px;
  width: 80%;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  small {
    color: $grisOcuro;
    font-weight: 700;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
}

.darkHighlight {
  border-radius: 100px;
  border: 1px solid rgba(17, 17, 17, 0.16);
  background: black;
  position: absolute;
  padding: 8px 26px;
  // width: 80%;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  small {
    color: var(--Beige, #f5f0e1);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
}

.servicioContainer {
  position: relative;
  border-radius: 24px;
  padding: 48px 24px 48px 24px;
  h2 {
    padding: 0 0 40px 0;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    color: $grisOcuro !important;
    text-align: center;
    font-family: "DM Sans";
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    span {
      display: flex;
      color: $secondaryColor;
      text-align: center;
      font-family: "DM Sans";
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      vertical-align: middle;
      margin-right: 0.3em !important;
    }
  }

  .note {
    text-align: center !important;
    margin-bottom: 24px;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-wrap: pretty;
  }

  button {
    padding: 16px 24px;
    margin-bottom: 24px;
  }

  ul {
    text-align: left;
    margin-top: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-left: 0px;

    li {
      margin-left: 0px;
      margin-bottom: 16px;
      list-style-type: none;
      padding-left: 40px;
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 24px 24px !important;
      min-height: 25px;
      hr {
        margin-bottom: 16px;
      }
    }
  }

  .footer {
    margin-top: 24px;
    text-align: left;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}

.dark {
  background-color: $grisOcuro;
  color: white;
  h2 {
    color: white !important;
  }
  h3 {
    color: $bgLigth !important;
  }
}
.light {
  background-color: white;
}

@media (max-width: $mobileBreak) {
  .servicios {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 0px;
  }

  .main {
    margin-top: 64px;

    h2 {
      font-size: 32px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 64px;
    }
    .servicioContainer {
      h2 {
        font-size: 44px;
        margin-bottom: 0px !important;
        span {
          font-size: 30px;
        }
      }
      h3 {
        font-size: 20px;
        line-height: 21px;
      }
    }
  }
}
