@import "../scss/colors.scss";
@import "../scss/layout.scss";
@import "../scss//landingsShared.scss";

#thanks {
  min-height: 100vh;
  height: 100%;
  padding: 100px !important;
  display: flex;
  justify-content: center;

  margin: 0px;
  // padding: 0px;
  .mesageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 0 12%;
    img {
      display: flex;
      height: 110px;
      margin-bottom: 40px;
    }

    // min-height: 100%;
    // height: calc(100vh - 100px);
    h1 {
      color: var(--Blanco, #fff);
      text-align: center;
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: 64px;
      margin-bottom: 24px;
    }
    p {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px !important;
      margin-bottom: 43px;
    }
    button {
      color: var(--Negro, #000);
      text-align: center;
      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      padding: 24px;
      gap: 10px;
      width: 100%;
    }
  }
  .btnContainer {
    .left {
      color: #fff;
      &:hover {
        color: black;
      }
    }
    img {
      height: 1em;
      margin: 0px;
    }
  }
}

@media (max-width: $mobileBreak) {
  #thanks {
    padding: 0px !important;
    margin: 0px;
    .logoContainer {
      .logoBox {
        justify-content: center;
      }
    }
    .mesageContainer {
      padding: 0px;
      margin: 0px;
      h1 {
        font-size: 36px;
        line-height: 33px;
      }
      p {
        font-size: 18px;
        margin-bottom: 30px;
      }
    }
    .btnContainer {
      margin-bottom: 2em;
      @include media-breakpoint-down(sm) {
        button {
          font-size: 13px;
        }
      }
    }
  }
}
