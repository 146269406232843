@import "../../scss/colors";
@import "../../scss/layout";

$sectionHeigth: 1128px;

.confia {
  min-height: $sectionHeigth;
  margin-top: 96px;
  margin-bottom: 96px;
  position: relative;
  h1 {
    color: $primaryColor;
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "DM Sans";
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;

    span {
      color: white;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "DM Sans";
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: 64px;
    }
  }
}

.row2 {
  bottom: 0px;
}

.imagenPhone {
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    min-width: 592px;
    min-height: 592px;
    background-color: $primaryColor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 50%;
  }
}

.itemContainer {
  min-height: 50%;
  margin: 0px;
  padding-top: 55px;
}

.itemLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p {
    margin-top: 24px;
    color: white;
    text-align: right;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
  }
  span {
    color: $primaryColor;
    font-weight: 700;
  }
  img {
    display: flex;
    height: 48px;
  }
}
.itemRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    margin-top: 24px;
    color: white;
    text-align: left;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
  }
  span {
    color: $primaryColor;
    font-weight: 700;
  }
  img {
    display: flex;
    height: 48px;
  }
}

@media (max-width: $mobileBreak) {
  .confia {
    h1 {
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; /* 100% */

      span {
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
      }
    }
  }

  .itemLeft {
    align-items: center;
    p {
      text-align: center;
    }
  }

  .itemRight {
    align-items: center;
    p {
      text-align: center;
    }
  }
}
