@import "../scss/landingsShared.scss";

.bgR {
  background-image: url(../assets/svg/EllipseRight.svg);
  background-repeat: no-repeat;
  background-position: right;
  margin-right: -12px;
  padding-right: 12px;

  @include media-breakpoint-down(md) {
    background-image: none;
  }
}
.bgL {
  background-image: url(../assets/svg/Ellipse.svg);
  background-repeat: no-repeat;
  background-position: left;
  margin-left: -12px;
  padding-left: 12px;

  @include media-breakpoint-down(md) {
    background-image: none;
  }
}

.main {
  max-width: 1440px !important;
  margin: 0px !important;
  padding: 0px !important;
}
