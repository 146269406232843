@import "../../scss/layout.scss";
@import "../../scss/landingsShared.scss";

.formSection {
  margin-top: 96px !important;
  margin-bottom: 96px !important;

  h4 {
    color: var(--Blanco, #fff);
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "DM Sans";
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 109.091% */

    span {
      color: $Naranja;
      font-weight: 700;
    }
    @include media-breakpoint-down(lg) {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 125% */
      padding-right: 10%;
      margin-bottom: 0px !important;
    }
  }
  h6 {
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 126.316% */
    text-align: center;
  }

  form {
    margin: 0px !important;
    padding: 0px !important;

    label {
      color: white !important;
      padding-bottom: 8px;
      display: flex;
    }

    input {
      display: flex;
      padding: 24px;
      align-items: flex-start;
      border-radius: 30px !important;
      background: white;
      box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.08) inset;
      height: 55px !important;
      width: 100%;
    }

    input::placeholder {
      color: black !important;
      opacity: 0.4 !important;
    }

    textarea::placeholder {
      color: black !important;
      opacity: 0.4 !important;
    }

    button {
      margin: 0px !important;
      height: 55px !important;
      width: unset !important;
      padding: 16px 24px;
      text-align: right;
      color: black;
      text-align: center;
      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 100% */
      img {
        padding-left: 10px;
        height: 24px;
        width: 24px;
      }
      input {
        margin-bottom: 0px;
        width: unset !important;
      }
    }
  }
}

@media (max-width: 992px) {
  h4 {
    padding: 0px !important;
  }
  form {
    input {
      width: 100% !important;
      padding: 12px !important;
      margin: 0 0 24px 0;
    }
    button {
      min-width: 100% !important;
    }
  }
}
