@import "../../../scss/landingsShared.scss";

.button {
  color: black !important;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 16px; /* 100% */
  padding: 15px 24px 15px 24px !important;
  span {
    margin-right: 10px;
  }
  @include media-breakpoint-down(md) {
    width: calc(100% - ($marginMobile * 2));
  }
}
