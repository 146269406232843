@import "./scss/colors.scss";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

:root {
  --bs-primary: #ffbf01 !important;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ffbf01;
  --bs-btn-border-color: #ffbf01;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #ffa000;
  --bs-btn-hover-border-color: #ffa000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #c59400;
  --bs-btn-active-border-color: #c59400;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ffbf01;
  --bs-btn-disabled-border-color: #ffbf01;
}

.btn-outline-primary {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #ffbf01;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ffa000;
  --bs-btn-hover-border-color: #ffa000;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ffa000;
  --bs-btn-active-border-color: #ffa000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffa000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffa000;
  --bs-gradient: none;
}

html {
  overflow-x: hidden;
}

body {
  font-family: "DM Sans";
  color: white;
  margin: 0;
  padding: 0px;
  background-color: $backgroundApp;
  overflow-x: hidden;
}
