@import "./scss/colors.scss";
@import "./scss/layout.scss";

.App {
  text-align: center;
  margin: 0px !important;
  padding: 0px !important;
}

@media (max-width: $mobileBreak) {
  .App {
    padding-left: 24px;
    padding-right: 24px;
  }
}
