@import "../../../scss/landingsShared.scss";

.FooterLanding {
  height: 96px;
  padding: 23px 70px 23px 70px;
  display: flex;
  margin-top: 96px !important;

  @include media-breakpoint-down(md) {
    padding: 0px;
    margin: 0px !important;
    .brand {
      justify-content: center;
    }
    .date {
      margin-top: $marginMobile;
      padding-bottom: $marginMobile;
      justify-content: center;
    }
  }
}

.brand {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  margin: 0px;
  p {
    color: white;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 135.714% */
    margin: 0px;
  }
  img {
    margin-right: 16px;
  }
}
.date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  p {
    color: white;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 135.714% */
    margin: 0px;
  }
}
