@import "../scss/landingsShared.scss";

.main {
  max-width: 1440px !important;
  margin: 0px !important;
  padding: 0px !important;
}
.mainFullW {
  // max-width: 1440px !important;
  margin: 0px !important;
  padding: 0px !important;
}

.bgR {
  background-image: url(../assets/svg/EllipseRight.svg);
  background-repeat: no-repeat;
  background-position: right;
  padding-right: 12px;

  @include media-breakpoint-down(md) {
    background-image: none;
    margin: 0px !important;
    padding: 0px !important;
  }
}
.bgL {
  background-image: url(../assets/svg/Ellipse.svg);
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 12px;

  @include media-breakpoint-down(md) {
    background-image: none;
    margin: 0px !important;
    padding: 0px !important;
  }
}

.explainerContainer {
  @include media-breakpoint-down(md) {
    margin: 0px !important;
    padding: 0px !important;
  }
}
