@import "../../scss/colors.scss";
@import "../../scss/layout.scss";

$seccionHeigth: 800px;

.HerroVideoBanner {
  height: $seccionHeigth;
  padding: 40px 0 113px 0;
  position: relative;
}

.heroText {
  text-align: left;
  padding: 0px;
  z-index: 10;

  small {
    color: white;
    font-feature-settings:
      "clig" off,
      "liga" off;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  h1 {
    margin-top: 40px;
    color: white;
    font-feature-settings:
      "clig" off,
      "liga" off;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    font-family: "DM Sans";
    font-size: 132px;
    font-style: normal;
    font-weight: 700;
    line-height: 132px;

    span {
      color: $primaryColor;
    }
  }
  h4 {
    margin-top: 40px;
    margin-bottom: 40px;
    color: white;
    font-feature-settings:
      "clig" off,
      "liga" off;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    font-family: "DM Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

    span {
      color: $secondaryColor;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "DM Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
  }

  button {
    color: black;
    text-align: center;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    padding: 24px;
    &:hover {
      color: black;
    }
  }
}

.vectorColor {
  fill: white !important;
}

.ctaContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-items: flex-start;
}

.videoBack {
  position: absolute;
  z-index: 8;
  min-height: 680px;
  height: 680px;
  left: 0px;
  padding: 0px !important;
  overflow: hidden;

  border-radius: 30px;
}

.videoStyle {
  width: 100%;
}

@media (max-width: $mobileBreak) {
  .HerroVideoBanner {
    height: 752px;
    padding: 0px;
    width: 100vw !important;
    margin-left: -12px !important;
    .heroText {
      padding-left: 46px;
      h1 {
        font-size: 64px;
        line-height: 64px;
      }

      h4 {
        padding-right: 46px;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        span {
          font-size: 24px;
          font-weight: 700;
          line-height: 24px;
        }
      }
    }

    .videoBack {
      border-radius: unset;
      display: flex;
      justify-content: center;
      // border: 2px solid red;
      width: 100vw !important;
    }

    .videoStyle {
      height: 180%;
      width: auto;
      display: flex;
    }
  }
}
