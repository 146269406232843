@import "../../../scss/landingsShared.scss";

.atencionContainer {
  height: 32px;
  padding: 7px 159px 6px 159px;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;

  @include media-breakpoint-down(md) {
    height: 64px;
    margin: 0 0px 0px 0 !important;
    padding: 16px 24px !important;
  }

  background-color: $Gris-oscuro;
  p {
    color: $Beige;
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    span {
      color: $Naranja;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }

    @include media-breakpoint-down(md) {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
      font-size: 14px;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
    }
  }
}
