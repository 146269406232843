#porqueElegirnos {
  margin-top: 230px;
  h2 {
    font-weight: 700;
    font-size: 64px;
    line-height: 80px;
    text-align: left;
  }
  p {
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: left;
    span {
      font-weight: 700;
    }
  }

  #gota2 {
    transform: scaleX(-1);
  }
  #imagem {
    img {
      width: 288px;
      height: 549px;
    }
  }
  .gotaData {
    position: absolute;
    width: 100%;
    padding-right: 14%;
    height: 100%;
    h4 {
      font-size: 96px;
      font-weight: 700;
      line-height: 96px;
      color: black;
      margin-top: 50px;
    }
    p {
      text-align: center;
      font-family: Lora;
      font-size: 31px;
      font-weight: 400;
      line-height: 39px;
      letter-spacing: 0px;
      color: black;
    }
  }
}

@media (max-width: 1023px) {
  #porqueElegirnos {
    margin-top: 96px;
    #imagem {
      img {
        width: 150px;
        height: 287px;
      }
    }

    .gotaData {
      margin-top: 2em;
    }

    #gota1 {
      width: 95%;
    }
    #gota2 {
      width: 95%;
    }
    .gotaData {
      padding-right: 0px;
      height: unset;
      h4 {
        margin-top: 10px;
        font-size: 48px;
        line-height: 30px;
      }
      p {
        font-size: 18px;
        line-height: 22px;
        margin-top: 10px;
        padding-bottom: 10px;
      }
    }
    svg {
      height: unset;
    }
    #gotaNaraja {
      justify-content: end;
    }
    #gotaBlanca {
      align-items: flex-end;
    }
    h2 {
      font-size: 48px;
      line-height: 56px;
      margin-top: 70px;
    }
    p {
      margin-top: 40px;
      font-size: 24px !important;
      line-height: 40px;
    }
  }
}
