@import "../../../scss/landingsShared.scss";

.hightLightText {
  padding-left: 71px;
  padding-right: 71px;
  h2 {
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "DM Sans";
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px; /* 100% */
    text-align: left;

    margin-top: 96px;

    @include media-breakpoint-down(md) {
      margin-top: 64px;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; /* 100% */
    }
  }

  @include media-breakpoint-down(md) {
    padding-left: $marginMobile !important;
    padding-right: $marginMobile !important;
    margin: 0px !important;
  }
}
