@import "../../scss/layout";
@import "../../scss/landingsShared.scss";

.formComponet {
  padding: 0px;
  margin: 0px;
  //   display: flex;
  width: 100%;
}
.itemForm {
  margin: 0px !important;
  align-content: flex-end;
  padding: 0px;
}
.emailBox {
  padding-right: 0px !important;
}

@media (max-width: $mobileBreak) {
  .emailBox {
    padding-right: 12px !important;
  }
}
