#rubros {
  margin-top: 68px;
  margin-bottom: 130px;
  color: white;
  .card {
    background-color: transparent;
    .card-img {
      width: 97px;
      align-self: flex-end;
    }
    .card-title {
      margin-top: 24px;
      margin-bottom: 0px;
      font-family: "Lora";
      font-size: 32px;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: left;
      color: white;
    }
    .card-subtitle {
      font-family: DM Sans;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0px;
      text-align: left;
      color: white;
    }
    .card-body {
      margin-top: 24px;
      font-family: "Lora";
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      padding: 0px;
      color: white;
    }
  }
}

@media (max-width: 1023px) {
  #rubros {
    .card {
      padding-right: 30px;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  #rubros {
    .card {
      .card-title {
        font-size: 26px !important;
      }
      .card-subtitle {
        font-size: 26px !important;
      }
    }
  }
}
