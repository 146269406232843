@import "../../../scss/landingsShared.scss";

.heroTex {
  h1 {
    margin-top: 98.5px;
    color: white;
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "DM Sans";
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px; /* 100% */
  }

  p {
    margin-top: 40px;
    color: $Naranja;
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 100% */
  }
  @include media-breakpoint-down(md) {
    margin: 0px !important;
    h1 {
      margin-top: 64px;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; /* 100% */
      padding: 0 15% 0 15%;
    }
    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 100% */
      padding-left: $marginMobile * 2;
      padding-right: $marginMobile * 2;
    }
  }
}
